import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import _ from 'lodash';

function App() {
  const [accessToken, setAccessToken] = useState(null);
  const [selectedGame, setSelectedGame] = useState('');
  const [reviews, setReviews] = useState([]);
  const [startIndex, setStartIndex] = useState(100);
  const maxSize = 200; // Changed from state to constant since it's not being updated
  // Removed ratingFilter and its setter since it's unused
  const [apiResponses, setApiResponses] = useState({});
  const [editableResponses, setEditableResponses] = useState({});
  const [localDeveloperResponses, setLocalDeveloperResponses] = useState({});
  const [inputFocus, setInputFocus] = useState({});
  const [perReviewLoading, setPerReviewLoading] = useState({}); // New state for per-review loading
  const [editingReviewId, setEditingReviewId] = useState(null);
  const [originalDeveloperResponse, setOriginalDeveloperResponse] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [overallGuidance, setOverallGuidance] = useState(localStorage.getItem('guidance') || 'Respond concisely to user reviews, keeping below 350 characters. Affirm positives and address concerns helpfully, encouraging users to try again or explore further. If addressing negative feedback, invite an improved star rating, using phrases similar to "Hope to earn those extra stars from you soon!" Be informed about features like play-to-earn and instant crypto payouts, mentioning them only when relevant. Maintain a warm tone to boost satisfaction and community sentiment. Provide response in the original language only. Don\'t return response in double or single quotes.');

  const [showGuidanceModal, setShowGuidanceModal] = useState(false);
  const [tempGuidance, setTempGuidance] = useState(overallGuidance);

  const [showRegenModal, setShowRegenModal] = useState(false);
  const [regenGuidance, setRegenGuidance] = useState('');
  const [currentReviewId, setCurrentReviewId] = useState(null);
  const [currentComment, setCurrentComment] = useState('');
  const [currentStars, setCurrentStars] = useState(null);

  // Request queue and concurrency control
  const requestQueue = useRef([]);
  const activeRequests = useRef(0);
  const CONCURRENT_LIMIT = 5;

  const openGuidanceModal = () => {
    setTempGuidance(overallGuidance);
    setShowGuidanceModal(true);
  };

  const closeGuidanceModal = () => {
    setShowGuidanceModal(false);
  };

  const saveOverallGuidance = () => {
    setOverallGuidance(tempGuidance);
    setShowGuidanceModal(false);
  };

  const openRegenModal = (reviewId, comment, stars) => {
    setRegenGuidance('');
    setCurrentReviewId(reviewId);
    setCurrentComment(comment);
    setCurrentStars(stars);
    setShowRegenModal(true);
  };

  const closeRegenModal = () => {
    setShowRegenModal(false);
  };

  const saveRegenGuidance = () => {
    addToQueue(currentReviewId, currentComment, currentStars, true, regenGuidance);
    setShowRegenModal(false);
  };

  const games = [
    { name: "5 Till You Die", packageName: "com.winrgames.fivetillyoudie" },
    { name: "Big Time", packageName: "com.winrgames.bigtime" },
    { name: "Brain Battle", packageName: "com.winrgames.brainbattle" },
    { name: "Brickz", packageName: "com.winrgames.brickz" },
    { name: "Brickz2", packageName: "com.winrgames.brickz2" },
    { name: "Bubble Burst", packageName: "com.winrgames.bubbleblast" },
    { name: "Bubble Burst 2", packageName: "com.winrgames.bubbleburst2" },
    { name: "Chess", packageName: "com.winrgames.chess" },
    { name: "ConnectOne", packageName: "com.winrgames.connectone" },
    { name: "Daring Descent", packageName: "com.winrgames.daringdescent" },
    { name: "Fancy Flyer", packageName: "com.winrgames.fancyflyer" },
    { name: "Free Cell", packageName: "com.winrgames.freecell" },
    { name: "GemDrop", packageName: "com.winrgames.gemdrop" },
    { name: "Mahjong", packageName: "com.winrgames.mahjong" },
    { name: "Octa Glow", packageName: "com.winrgames.tenten" },
    { name: "Pipe Dreams", packageName: "com.winrgames.pipedreams" },
    { name: "Puzzle Ball", packageName: "com.winrgames.puzzleball" },
    { name: "Solitaire", packageName: "com.winrgames.solitaire" },
    { name: "Solitaire 2", packageName: "com.winrgames.solitaire2" },
    { name: "Sudoku", packageName: "com.winrgames.sudoku" },
    { name: "Toy Town", packageName: "com.winrgames.toytown" },
    { name: "Words Words Words", packageName: "com.winrgames.wordswordswords" }
  ];

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const token = params.get("access_token");
    document.title = 'WINRGames: Review Manager'

    if (token) {
      setAccessToken(token);
    } else {
      authenticate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessToken && selectedGame) {
      fetchReviews(startIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, selectedGame, startIndex]);

  useEffect(() => {
    if (!isLoading && reviews.length > 0) {
      console.log('Reviews updated:', reviews);
    }
  }, [reviews, isLoading]);

  useEffect(() => {
    localStorage.setItem('guidance', overallGuidance);
  }, [overallGuidance]);

  function stripQuotes(input) {
    return input.replace(/^['"](.*)['"]$/, '$1');
  }

  const authenticate = () => {
    const clientId = "104125201667-7obvbuvj8uu4csqa3ddo1f5tri6745l0.apps.googleusercontent.com";
    const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    const scope = encodeURIComponent("https://www.googleapis.com/auth/androidpublisher");
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=${scope}&include_granted_scopes=true`;
    window.location.href = authUrl;
  };

  const fetchReviews = (startIdx) => {
    setIsLoading(true);
    let reviewsUrl = `https://androidpublisher.googleapis.com/androidpublisher/v3/applications/${selectedGame}/reviews?access_token=${accessToken}&maxResults=${maxSize}&startIndex=${startIdx}`;

    fetch(reviewsUrl)
      .then(response => {
        if (response.status === 401) {
          console.error("Error 401: Unauthorized access. Reauthenticating...");
          authenticate(); // Re-initiate authentication
          return;
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          setReviews(data.reviews || []);

          if (data.reviews) {
            data.reviews.forEach(review => {
              if (!review.comments.some(c => c.developerComment)) {
                addToQueue(review.reviewId, review.comments[0].userComment.text, review.comments[0].userComment.starRating);
              } else {
                removeLocalDeveloperResponse(review.reviewId);
              }
            });
          }

          setIsLoading(false);
        }
      })
      .catch(error => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  };

  const addToQueue = (reviewId, comment, stars, force = false, reviewGuidance = null) => {
    requestQueue.current.push({ reviewId, comment, stars, force, reviewGuidance });
    processQueue();
  };

  const processQueue = () => {
    while (activeRequests.current < CONCURRENT_LIMIT && requestQueue.current.length > 0) {
      const { reviewId, comment, stars, force, reviewGuidance } = requestQueue.current.shift();
      activeRequests.current += 1;
      fetchAISuggestion(reviewId, comment, stars, force, reviewGuidance)
        .finally(() => {
          activeRequests.current -= 1;
          processQueue();
        });
    }
  };

  const removeLocalDeveloperResponse = (reviewId) => {
    setLocalDeveloperResponses(prev => {
      const newResponses = { ...prev };
      delete newResponses[reviewId];  // Remove the key-value pair
      return newResponses;
    });
  };

  const fetchAISuggestion = async (reviewId, comment, stars, force = false, reviewGuidance = null) => {
    if (!force) {
      const currentReview = reviews.find(r => r.reviewId === reviewId);
      if (currentReview && currentReview.comments.some(c => c.developerComment)) {
        return;
      }
    }

    // Set per-review loading state
    setPerReviewLoading(prev => ({
      ...prev,
      [reviewId]: true
    }));

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        content: comment,
        overallGuidance,
        reviewGuidance,
        stars,
      })
    };

    if (force) {
      setApiResponses(prevResponses => ({
        ...prevResponses,
        [reviewId]: 'Loading...'
      }));
    }

    try {
      const response = await fetch(process.env.REACT_APP_GROQ_ENDPOINT, requestOptions);
      const data = await response.json();
      if (_.get(data, 'choices[0].message.content', null) !== null) {
        const newContent = stripQuotes(data.choices[0].message.content);
        setApiResponses(prev => ({
          ...prev,
          [reviewId]: newContent
        }));
        setEditableResponses(prev => ({
          ...prev,
          [reviewId]: newContent
        }));
      } else {
        setApiResponses(prev => ({
          ...prev,
          [reviewId]: 'No suggestion available'
        }));
      }
    } catch (error) {
      console.error('Error fetching API response:', error);
      setApiResponses(prevResponses => ({
        ...prevResponses,
        [reviewId]: 'Failed to fetch response'
      }));
    } finally {
      // Clear per-review loading state
      setPerReviewLoading(prev => ({
        ...prev,
        [reviewId]: false
      }));
    }
  };

  const handleRegenerate = (reviewId, comment, stars) => {
    addToQueue(reviewId, comment, stars, true);
  };

  const submitReplyAndUpdate = async (reviewId, replyText) => {
    setPerReviewLoading(prev => ({
      ...prev,
      [reviewId]: true
    }));
    const success = await submitReply(reviewId, replyText);
    if (success) {
      setLocalDeveloperResponses(prev => ({
        ...prev,
        [reviewId]: replyText
      }));
    }
    setPerReviewLoading(prev => ({
      ...prev,
      [reviewId]: false
    }));
  };

  const submitReply = async (reviewId, replyText) => {
    const url = `https://androidpublisher.googleapis.com/androidpublisher/v3/applications/${selectedGame}/reviews/${reviewId}:reply`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({ replyText })
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data.result && data.result.replyText) {
        console.log("Reply submitted:", data);
        return true;
      }
    } catch (error) {
      console.error('Error submitting reply:', error);
    }
    return false;
  };

  const changePage = (direction) => {
    if (direction === 'next' && reviews.length === maxSize) {
      setStartIndex(prev => prev + maxSize);
      fetchReviews(startIndex + maxSize);
    } else if (direction === 'prev' && startIndex >= maxSize) {
      setStartIndex(prev => prev - maxSize);
      fetchReviews(startIndex - maxSize);
    }
  };

  const handleGameSelection = (event) => {
    const packageName = event.target.value;
    setApiResponses({});
    setEditableResponses({});
    setReviews([]);
    setStartIndex(0);
    setSelectedGame(packageName);
  };

  const renderStarRating = (rating) => {
    const filledStars = '★'.repeat(rating);
    const emptyStars = '☆'.repeat(5 - rating);
    return filledStars + emptyStars;
  };

  // Toggle input focus
  const handleFocus = (id) => {
    setInputFocus(prev => ({ ...prev, [id]: true }));
  };

  const handleBlur = (id, value) => {
    setEditableResponses(prev => ({ ...prev, [id]: value }));
    setInputFocus(prev => ({ ...prev, [id]: false }));
  };

  const parseDate = (timestamp) => {
    return new Date(timestamp.seconds * 1000 + timestamp.nanos / 1000000);
  };

  const isUserCommentUpdated = (review) => {
    const userComment = review.comments.find(comment => comment.userComment)?.userComment;
    const developerComment = review.comments.find(comment => comment.developerComment)?.developerComment;

    if (!developerComment) return false;
    const userDate = parseDate(userComment.lastModified);
    const developerDate = parseDate(developerComment.lastModified);
    return userDate > developerDate;
  };

  const handleEditReply = (reviewId, developerComment) => {
    setEditingReviewId(reviewId);
    setOriginalDeveloperResponse(developerComment);
    setEditableResponses(prev => ({
      ...prev,
      [reviewId]: developerComment
    }));
  };

  const handleCancelEdit = () => {
    if (editingReviewId) {
      setEditableResponses(prev => ({
        ...prev,
        [editingReviewId]: originalDeveloperResponse
      }));
      setEditingReviewId(null);
    }
  };

  return (
    <div className="App">
      <div style={{
        padding: '10px',
        backgroundColor: '#f0f0f0',
        marginBottom: '20px',
        display: 'flex',        // Enables flexbox
        alignItems: 'center',   // Aligns children vertically in the center
        justifyContent: 'start' // Aligns children to the start of the flex container
      }}>
        <strong>Games:</strong>
        <select onChange={handleGameSelection} value={selectedGame} style={{ marginLeft: '10px' }}>
          <option value="">Select a game...</option>
          {games.map(game => (
            <option key={game.packageName} value={game.packageName}>
              {game.name}
            </option>
          ))}
        </select>
        <div style={{ paddingLeft: '20px' }}>
          <button onClick={openGuidanceModal} style={{ marginLeft: '20px' }}>Edit Overall Guidance</button>
        </div>
      </div>

      {showGuidanceModal && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <textarea
              value={tempGuidance}
              onChange={e => setTempGuidance(e.target.value)}
              rows="10"
              cols="50"
            />
            <div>
              <button onClick={saveOverallGuidance}>Save</button>&nbsp;
              <button onClick={closeGuidanceModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {showRegenModal && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <textarea
              value={regenGuidance}
              onChange={e => setRegenGuidance(e.target.value)}
              placeholder="Enter guidance text for regeneration..."
              rows="10"
              cols="50"
            />
            <div>
              <button onClick={saveRegenGuidance}>Regenerate</button>&nbsp;
              <button onClick={closeRegenModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <p>Loading reviews...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Date</th>
              <th>User Comment</th>
              <th>AI Suggestion</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {reviews.filter(review =>
              // Since ratingFilter is removed, adjust the filter accordingly or remove it
              true
              // Alternatively, if you decide to keep ratingFilter, ensure it's being used
              // ratingFilter === '' || review.comments[0].userComment.starRating === parseInt(ratingFilter)
            ).map((review) => (
              <React.Fragment key={review.reviewId}> {/* Changed key to reviewId */}
                <tr>
                  <td>{review.authorName}</td>
                  <td>{new Date(review.comments[0].userComment.lastModified.seconds * 1000).toLocaleDateString()}</td>
                  <td>
                    {review.comments.map((comment, commentIndex) => (
                      <React.Fragment key={commentIndex}>
                        {comment.userComment && (
                          <div>
                            <strong>User:</strong> {comment.userComment.text}
                            {isUserCommentUpdated(review) && (
                              <span className='updated-label'>Updated</span>
                            )}
                          </div>
                        )}
                        {comment.developerComment && !localDeveloperResponses[review.reviewId] && (
                          <div>
                            <br />
                            <strong>Developer:</strong> {comment.developerComment.text}
                            &nbsp;
                            <button className="link-button" onClick={() => handleEditReply(review.reviewId, comment.developerComment.text)}>Edit Reply</button>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                    {localDeveloperResponses[review.reviewId] && (
                      <div>
                        <br />
                        <strong>Developer:</strong> {localDeveloperResponses[review.reviewId]}
                        <button className="link-button" onClick={() => handleEditReply(review.reviewId, localDeveloperResponses[review.reviewId])}>Edit Reply</button>
                      </div>
                    )}
                  </td>
                  <td>
                    {(editingReviewId === review.reviewId || (!review.comments.some(c => c.developerComment) && localDeveloperResponses[review.reviewId] == null)) && (
                      <>
                        <div
                          contentEditable
                          suppressContentEditableWarning={true}
                          onFocus={() => handleFocus(review.reviewId)}
                          onBlur={(e) => handleBlur(review.reviewId, e.currentTarget.textContent)}
                          style={{
                            border: inputFocus[review.reviewId] ? '1px solid #85e085' : '1px dashed',
                            backgroundColor: inputFocus[review.reviewId] ? '#ccffcc' : 'transparent',
                            minHeight: '20px',
                            padding: '5px',
                            cursor: 'text',
                            outline: 'none',
                            color: 'black',
                            fontSize: '16px'
                          }}>
                          {editableResponses[review.reviewId] || apiResponses[review.reviewId] || 'Loading...'}
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                          <button onClick={() => handleRegenerate(review.reviewId, review.comments[0].userComment.text, review.comments[0].userComment.starRating)} disabled={perReviewLoading[review.reviewId] || apiResponses[review.reviewId] === 'Loading...'}>Regenerate</button>&nbsp;
                          <button onClick={() => openRegenModal(review.reviewId, review.comments[0].userComment.text, review.comments[0].userComment.starRating)} disabled={perReviewLoading[review.reviewId] || apiResponses[review.reviewId] === 'Loading...'}>Regen + Guidance</button>&nbsp;
                          <button
                            onClick={() => submitReplyAndUpdate(review.reviewId, editableResponses[review.reviewId] || apiResponses[review.reviewId])} disabled={perReviewLoading[review.reviewId] || apiResponses[review.reviewId] === 'Loading...' || (!editableResponses[review.reviewId] && !apiResponses[review.reviewId])}>Approve This Reply</button>
                          {editingReviewId === review.reviewId && (
                            <button className="cancel-button" onClick={handleCancelEdit}>Cancel</button>
                          )}
                        </div>
                      </>
                    )}
                  </td>
                  <td>{renderStarRating(review.comments[0].userComment.starRating)}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>

        </table>
      )}
      <div>
        <button onClick={() => changePage('prev')} disabled={startIndex === 0 || isLoading}>Previous</button>&nbsp;
        <button onClick={() => changePage('next')} disabled={reviews.length < maxSize || isLoading}>Next</button>
      </div>
    </div>
  );
}

export default App;